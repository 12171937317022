/**
 * Localization resources for the Insight domain
 */
import i18next from 'i18next';

// Import locale resources
import { navigationMenuEn } from 'src/domains/insight/common/components/navigationMenu/navigationMenu.locales';
import { savedForLaterEn } from 'src/domains/insight/common/components/saveForLater/saveForLater.locale';
import { authorsEn } from 'src/domains/insight/common/locales/authors';
import { commonEn } from 'src/domains/insight/common/locales/common';
import { homepageEn } from 'src/domains/insight/homepage/homepage.locale';
import { searchPageEn } from 'src/domains/insight/searchPage/searchPage.locale';
import { widgetsEn } from 'src/domains/insight/widgets/InsightWidget.locale';

// Initialize i18next with our translations
const i18n = i18next.createInstance();
i18n.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      common: commonEn,
      authors: authorsEn,
      homepage: homepageEn,
      searchPage: searchPageEn,
      widgets: widgetsEn,
      savedForLater: savedForLaterEn,
      navigationMenu: navigationMenuEn,
    },
  },
  ns: ['common', 'authors', 'homepage', 'searchPage', 'widgets', 'savedForLater', 'navigationMenu'],
  interpolation: {
    escapeValue: false,
  },
});

// Helper function to get translations
export const t = (key: string, options?: Record<string, any>) => i18n.t(key, options);
