import { t } from 'src/domains/insight/common/locales';

import type { CommoditySection, CommoditySlug } from './types';

export const INSIGHT_NAVIGATION_SECTIONS: Record<CommoditySlug, CommoditySection> = {
  'cross-commodities': {
    slug: 'cross-commodities',
    title: t('navigationMenu:commodityTitles.crossCommodities'),
    hasNews: false,
    hasResearchUpdates: true,
    hasWebinars: true,
    weeklyReport: [
      t('navigationMenu:weeklyReports.crossCommodity'),
      t('navigationMenu:weeklyReports.macro'),
    ],
    monthlyReport: [t('navigationMenu:monthlyReports.commodityGeopolitics')],
    hashtagIds: ['32', '33'],
  },
  oil: {
    slug: 'oil',
    title: t('navigationMenu:commodityTitles.oil'),
    hasResearchUpdates: true,
    hasNews: false,
    hasWebinars: true,
    weeklyReport: [
      t('navigationMenu:weeklyReports.crudeOil'),
      t('navigationMenu:weeklyReports.refinedProducts'),
      t('navigationMenu:weeklyReports.refineryMargins'),
      t('navigationMenu:weeklyReports.tankers'),
    ],
    monthlyReport: [
      t('navigationMenu:monthlyReports.crudeView'),
      t('navigationMenu:monthlyReports.crudeOilBarrel'),
      t('navigationMenu:monthlyReports.refinedProductsView'),
      t('navigationMenu:monthlyReports.topBarrel'),
      t('navigationMenu:monthlyReports.middleBarrel'),
      t('navigationMenu:monthlyReports.bottomBarrel'),
      t('navigationMenu:monthlyReports.tankers'),
      t('navigationMenu:monthlyReports.biofuels'),
    ],
    parentCommodityTagIds: ['2', '31', '69', '7'],
  },
  'gas-lng': {
    slug: 'gas-lng',
    title: t('navigationMenu:commodityTitles.gasLNG'),
    hasNews: true,
    hasResearchUpdates: true,
    hasWebinars: true,
    weeklyReport: [t('navigationMenu:weeklyReports.naturalGasLNG')],
    monthlyReport: [
      t('navigationMenu:monthlyReports.lngSpotlight'),
      t('navigationMenu:monthlyReports.lng'),
      t('navigationMenu:monthlyReports.europeanGas'),
    ],
    parentCommodityTagIds: ['1', '29'],
  },
  dry: {
    slug: 'dry',
    title: t('navigationMenu:commodityTitles.dry'),
    hasNews: false,
    hasResearchUpdates: true,
    hasWebinars: true,
    weeklyReport: [t('navigationMenu:weeklyReports.dryBulks')],
    monthlyReport: [
      t('navigationMenu:monthlyReports.thermalMetCoal'),
      t('navigationMenu:monthlyReports.ironOre'),
      t('navigationMenu:monthlyReports.grains'),
    ],
    parentCommodityTagIds: ['21'],
  },
  'risk-and-compliance': {
    slug: 'risk-and-compliance',
    title: t('navigationMenu:commodityTitles.riskAndCompliance'),
    hasNews: false,
    hasResearchUpdates: true,
    hasWebinars: false,
    weeklyReport: [],
    monthlyReport: [],
    parentCommodityTagIds: ['67'],
  },
  power: {
    slug: 'power',
    title: t('navigationMenu:commodityTitles.power'),
    hasNews: false,
    hasResearchUpdates: true,
    hasWebinars: false,
    weeklyReport: [],
    monthlyReport: [],
    parentCommodityTagIds: ['28'],
  },
};
