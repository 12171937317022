import { AppRoutes } from 'src/app/routes';
import store from 'src/store';

import { arbitrageRoutesName } from './constants';
import { userHasAccessToArbitrage } from './permissions/useArbsPermissions';

import { createAuthorizationHook } from 'src/helpers/router.helper';

import type { RouteRecordRaw } from '@kpler/vue2-utils';

const ArbitrageWorkspace = () => import('./ArbitrageWorkspace.vue');

const Dashboard = () => import('./pages/dashboard/Arbitrage.vue');

const arbitrageRoutes: RouteRecordRaw = {
  name: 'arbitrage',
  path: 'arbitrage',
  meta: { title: 'Arbitrage' },
  component: ArbitrageWorkspace,
  redirect: '/arbitrage/dashboard',
  beforeEnter: createAuthorizationHook(() => {
    const hasAccessToArbsDomain = userHasAccessToArbitrage(store);
    return hasAccessToArbsDomain();
  }, AppRoutes.FORBIDDEN),
  children: [
    {
      name: arbitrageRoutesName.DASHBOARD,
      path: 'dashboard',
      component: Dashboard,
    },
  ],
};

export default arbitrageRoutes;
