import { computed } from 'vue';

import type { AppStore } from 'src/store/types';

const ARBITRAGE_PERMISSION = 'arbitrage:dashboard';

export const useArbsPermissions = (store: AppStore) => {
  const hasArbitragePermissions = computed<boolean>(() =>
    store.getters.userHasOnePermission(['arbitrage:dashboard']),
  );

  const hasArbsDashboardPermissions = computed<boolean>(() =>
    store.getters.userHasPermission('arbitrage:dashboard'),
  );

  return {
    hasArbitragePermissions,
    hasArbsDashboardPermissions,
  };
};

export const userHasAccessToArbitrage = (store: AppStore) => () =>
  store.getters.userHasPermission(ARBITRAGE_PERMISSION);
