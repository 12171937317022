export const searchPageEn = {
  contentTypes: {
    news: 'News',
    reports: 'Reports',
    marketPulse: 'Market Pulse',
    webinars: 'Webinars',
  },
  publishedFilter: {
    title: 'Published',
    timeframeTypes: {
      last7Days: 'Last 7 days',
      last14Days: 'Last 14 days',
      last30Days: 'Last 30 days',
      lastQuarter: 'Last quarter',
      thisYear: 'This year',
      lastYear: 'Last year',
      all: 'All',
    },
  },
};
